<template>
  <div class="navigation-menu-click" :class="{ 'navigation-menu-click--open': open }" @click="close()">
  </div>
</template>

<script>
import EventBus from "../services/event-bus";

export default {
  name: "NavigationMenuClick",
  data() {
    return {
      open: false
    };
  },
  mounted() {
    EventBus.$on("toggleMenu", () => {
      this.open ^= true;
    });
  },
  methods: {
    close() {
      EventBus.$emit("toggleMenu");
    }
  }
};
</script>

<style lang="scss" scoped>
.navigation-menu-click {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-out;

  &:not(.navigation-menu-click--open) {
    pointer-events: none;
    background: rgba(0, 0, 0, 0);
  }
}
</style>
