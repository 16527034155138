<template>
  <div class="navigation-menu" :class="{ 'navigation-menu--open': open }">

    <div class="navigation-menu__header">
      <slot name="header"></slot>
      <button class="button--close" @click="close()">
        <i class="icon--close"></i>
      </button>
    </div>

    <div class="navigation-menu__content">
      <slot></slot>
    </div>

  </div>
</template>

<script>
import EventBus from "./../services/event-bus";

export default {
  name: "NavigationMenu",
  data() {
    return {
      open: false
    };
  },
  mounted() {
    EventBus.$on("toggleMenu", () => {
      this.open ^= true;
      if(this.open){
         document.body.classList.add("scroll-lock");
      }else{
        document.body.classList.remove("scroll-lock");
      }
    });
  },
  methods: {
    close() {
      EventBus.$emit("toggleMenu");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./../styles/abstracts/_breakpoints";
@import "./../styles/abstracts/_variables";
@import "./../styles/abstracts/_colors";

.navigation-menu {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  background: #fff;
  transition: all 0.5s ease-out;

  @include tablet {
    max-width: 375px;
  }

  &:not(.navigation-menu--open) {
    transform: translateX(100%);
    box-shadow: 0 0 50px rgba(0, 0, 0, 0);
  }

  .button--close {
    margin-left: auto;
    padding: 0;
    max-width: 32px;
  }

  .icon--close {
    content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-icon="times" data-prefix="fal" viewBox="0 0 320 512"><path fill="%23495157" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"/></svg>');
    height: 32px;
  }
}

.navigation-menu__header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 $mobile-grid-padding;
  height: $navigation-height;

  &:after{ 
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: $light-grey;
    left: $mobile-grid-padding;
    max-width: calc(100% - #{$mobile-grid-padding * 2});
  }

  .logo {
    img {
      height: 32px;
    }
  }
}

.navigation-menu__content {
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: $mobile-grid-padding;
  height: 100%;

  a:not(.button--primary),
  p {
    display: flex;
    margin-top: 16px;
    color: $title-color;
    font-weight: 400;
  }

  .is-cta {
    margin-top: auto;
  }

  .navigation-sub-items {
    margin: 0 0 16px;

    a {
      margin: 16px 0 0 16px;
      color: $text-color;
      font-weight: 300;
    }
  }
}
</style>
