<template>
  <button class="button--menu" @click="open()">
  </button>
</template>

<script>
import EventBus from "./../services/event-bus";

export default {
  name: "MenuButton",
  methods: {
    open() {
      EventBus.$emit("toggleMenu");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./../styles/abstracts/_breakpoints";

.button--menu {
  margin-left: auto;
  padding: 4px;
  height: 42px;
  width: 42px;
  content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-bars fa-w-14 fa-3x" data-icon="bars" data-prefix="fal" viewBox="0 0 448 512"><path fill="%23495157" d="M442 114H6a6 6 0 01-6-6V84a6 6 0 016-6h436a6 6 0 016 6v24a6 6 0 01-6 6zm0 160H6a6 6 0 01-6-6v-24a6 6 0 016-6h436a6 6 0 016 6v24a6 6 0 01-6 6zm0 160H6a6 6 0 01-6-6v-24a6 6 0 016-6h436a6 6 0 016 6v24a6 6 0 01-6 6z"/></svg>');
  color: #fff;

  @include tablet-landscape {
    display: none;
  }
}

.section--homepage .button--menu {
    content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-bars fa-w-14 fa-3x" data-icon="bars" data-prefix="fal" viewBox="0 0 448 512"><path fill="%23fff" d="M442 114H6a6 6 0 01-6-6V84a6 6 0 016-6h436a6 6 0 016 6v24a6 6 0 01-6 6zm0 160H6a6 6 0 01-6-6v-24a6 6 0 016-6h436a6 6 0 016 6v24a6 6 0 01-6 6zm0 160H6a6 6 0 01-6-6v-24a6 6 0 016-6h436a6 6 0 016 6v24a6 6 0 01-6 6z"/></svg>');
}
</style>
